import { redirect } from 'next/navigation'
import { getRequestConfig } from 'next-intl/server'

export const LOCALES = {
	en: 'English',
	he: 'עברית',
	ar: 'العربية',
}

export const LOCALES_KEYS = Object.keys(LOCALES) as (keyof typeof LOCALES)[]

export default getRequestConfig(async ({ locale }) => {
	// Validate that the incoming `locale` parameter is valid
	if (!LOCALES_KEYS.includes(locale as any)) redirect('/en')

	return {
		messages: (await import(`./locales/${locale}.json`)).default,
	}
})
