import { useLocale, useTranslations } from 'next-intl';
import { getName } from 'i18n-iso-countries';
import { cn } from '@/lib/utils/cn';
import Image from 'next/image';
import { Region } from '@/lib/types';

const Country = ({
	country_code,
	image,
	title,
	price_from,
	active,
	setActive,
}: Props) => {
	const locale = useLocale();
	const t = useTranslations('Country');

	// Fallback to `title` if `getName` doesn't find a localized name
	const name = getName(country_code, locale) || title;

	return (
		<div
			className={cn(
				'flex flex-col items-center justify-between p-6 rounded-3xl bg-white min-h-48 md:min-h-60 min-w-44 md:min-w-56 duration-200 cursor-pointer hover:shadow-lg',
				active ? 'border-2 border-blue-500' : ''
			)}
			onClick={setActive}
		>
			<div className="w-[40px] h-[25px] md:w-[80px] md:h-[50px] rounded-lg border overflow-hidden flex items-center justify-center mb-3">
				<Image
					src={image}
					alt={title}
					className="object-cover w-full h-full"
					width={80}
					height={50}
				/>
			</div>
			<h4 className="text-center font-semibold text-2xl text-gray-800">{name}</h4>
			{price_from !== undefined && (
				<p className="text-center text-[#242020] mt-1 flex gap-0 flex-col">
					<span className='opacity-30 text-sm md:text-base font-semibold'>{t('from')}</span>
					<span className='text-2xl md:text-4xl font-bold'>${price_from.toFixed(2)}</span>
				</p>
			)}
		</div>
	);
};

interface Props extends Region {
	active: boolean;
	setActive: () => void;
	price_from: number; // Add this field to show the price
}

export default Country;