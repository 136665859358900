import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

interface EsimsModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const EsimsModal: React.FC<EsimsModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const modalContent = (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleClose}
    >
      <div className="bg-white p-6 rounded-2xl relative w-11/12 max-w-3xl mx-auto max-h-[90vh] overflow-scroll">
        <button
          className="absolute top-2 right-2 text-black text-4xl"
          onClick={onClose}
          aria-label="Close Modal"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.body);
};

export default EsimsModal;
