import { useTranslations } from 'next-intl'
import Tag from './Tag'

const DataPlan = ({ active, onClick, day, data, isBestPrice, isVoiceAndData }: Props) => {
	const t = useTranslations('DataPlan')

	const bestPrice = t('tag_best_price')
	const voiceAndData = t('voice_n_data')
	return (
		<div
			className={`relative rounded-2xl h-28 lg:h-36 flex flex-col justify-between p-3 cursor-pointer min-w-24 ${
				active ? 'bg-gradient text-white' : 'bg-[#F9F9F9] text-[#242020]'
			}`}
			onClick={onClick}
		>
			{/* {isBestPrice && (
				<Tag
					text={bestPrice}
					backgroundColor={active ? '#C8E1FD' : '#f8eed3'}
					textColor={active ? '#2189FF' : '#f6b60b'}
				/>
			)} */}
			{isVoiceAndData && (
				<Tag
					text={voiceAndData}
					backgroundColor={active ? '#C8E1FD' : '#f8eed3'}
					textColor={active ? '#2189FF' : '#f6b60b'}
				/>
			)}
			<h2 className={`text-lg font-bold flex-grow`}>{data}</h2>
			<span className={`text-sm font-semibold ${active ? '' : 'text-[#242020] text-opacity-30'}`}>
				{t('homepage_plans_valid_for')} {day} {t('homepage_plans_days')}
			</span>
		</div>
	)
}

export const DataPlanSkeleton = () => (
	<div className='rounded-2xl h-28 lg:h-36 flex flex-col justify-between p-3 cursor-pointer min-w-20 lg:min-w-24 bg-[#F9F9F9] text-[#242020] animate-pulse'>
		<h2 className={`text-lg font-bold`}></h2>
		<span className={`text-sm font-semibold text-[#242020] text-opacity-30`}></span>
	</div>
)

export interface Plan {
	amount: number
	data: string
	day: number
	id: string
	is_unlimited: false
	manual_installation: string
	net_price: number
	price: number
	qr_installation: string
	short_info: null
	text: number | null
	title: string
	type: string
	voice: number | null
	isBestPrice?: boolean
}

interface Props extends Plan {
	active: boolean
	onClick: () => void
	isBestPrice?: boolean
	isVoiceAndData?: boolean
}

export default DataPlan
